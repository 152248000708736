import axios from "axios";

import { ILogin, IToken } from "src/models";
import { handleGlobalError } from "src/lib/utils";

import api from "../api";

const acquiretalentDomain = process.env.REACT_APP_ACQUIRETALENT_DOMAIN;
const cvcheckerDomain = process.env.REACT_APP_CVCHCKER_DOMAIN;

export const loginAccount = async (credentials: {
  email: string;
  password: string;
  currentPlatform: string;
}): Promise<ILogin | undefined> => {
  const url =
    credentials.currentPlatform === "acquiretalent"
      ? `${acquiretalentDomain}/auth/login`
      : `${cvcheckerDomain}/auth/admin/login`;

  const { email, password } = credentials;
  try {
    const res = await axios.post(url, { email, password });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const refreshToken = async (refreshToken: string): Promise<IToken | undefined> => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    };

    // **staging **
    const requestBody = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=5h00tgv485765k9ht2ueuend1f`;

    // ** production **
    // const requestBody = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=6bccv1gs20u80fquaebtviltcv`;

    const res = await axios.post(`${process.env.REACT_APP_SOCIAL_URL}/oauth2/token`, requestBody, requestOptions);

    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const logoutAccount = async () => {
  try {
    await api.post("/auth/logout");
  } catch (error) {
    console.error(error);
  }
};
