import { toast } from "sonner";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";

import { Button } from "src/shadcn/ui/button";
import { RootState } from "src/store/store";
import { IBlog, IBlogIdList } from "src/models";
import {
  getHrBlogs,
  getNeuralpitBlogs,
  getCvcheckerBlogs,
} from "src/services/blog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/shadcn/ui/card";

const Blogs = () => {
  const navigate = useNavigate();
  const currentPlatform = useSelector(
    (state: RootState) => state.platform.currentPlatform,
  );

  // refs
  const blogIdList = useRef<IBlogIdList[]>([]);

  // states
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // functions
  const handleGetBlogs = async (page: number, lastBlogId?: string) => {
    const fnc =
      currentPlatform === "acquiretalent"
        ? getHrBlogs
        : currentPlatform === "neuralpit"
        ? getNeuralpitBlogs
        : getCvcheckerBlogs;

    if (!fnc) {
      toast.error("Please select other platform");
      return;
    }

    setIsLoading(true);
    const res = await fnc(lastBlogId);
    setIsLoading(false);

    setBlogs(res?.blogs || []);
    setTotalPages(Math.ceil((res?.total || 0) / 8));

    if (res?.blogs && res?.blogs?.length > 0) {
      const pageExists = blogIdList.current.some(
        (item) => item.page === page && item.platform === currentPlatform,
      );
      if (!pageExists) {
        blogIdList.current.push({
          lastBlogId: res.blogs[res.blogs.length - 1].id,
          page: page,
          platform: currentPlatform,
        });
      }
    }
  };

  // effects
  useEffect(() => {
    if (currentPage === 1) {
      handleGetBlogs(currentPage);
    } else {
      const lastBlogId = blogIdList.current.find(
        (item) =>
          item.page === currentPage - 1 && item.platform === currentPlatform,
      )?.lastBlogId;
      handleGetBlogs(currentPage, lastBlogId);
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      handleGetBlogs(currentPage);
    } else {
      setCurrentPage(1);
    }
  }, [currentPlatform]);

  return (
    <div>
      <div className="flex justify-end">
        <Button asChild>
          <Link to="/blogs/new">New Blog</Link>
        </Button>
      </div>

      {isLoading && (
        <div className="w-full h-[60vh] flex items-center justify-center">
          <Loader2 className="animate-spin size-10 text-green-500" />
        </div>
      )}

      {!isLoading && blogs && blogs?.length === 0 && (
        <div className="w-full h-[60vh] flex items-center justify-center">
          <p className="text-lg font-semibold">No blogs found</p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5 gap-6">
        {!isLoading &&
          blogs?.length > 0 &&
          blogs.map((blog) => (
            <Card
              key={blog.id}
              className="cursor-pointer"
              onClick={() => {
                navigate(`/blogs/${blog.id}`);
              }}
            >
              <CardHeader>
                <CardTitle>{blog.title}</CardTitle>
                <CardDescription className="hidden">sss</CardDescription>
                <div
                  dangerouslySetInnerHTML={{ __html: blog.html }}
                  className="line-clamp-2 text-sm"
                />
              </CardHeader>
              <CardContent className="">
                {blog.cover_pic ? (
                  <img
                    src={blog.cover_pic}
                    alt={blog.title}
                    className="w-full h-auto object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-full flex items-center justify-center">
                    <span>No Image</span>
                  </div>
                )}
              </CardContent>
            </Card>
          ))}
      </div>

      {/* {totalPages > 1 && ( */}
      <div className="mt-6 flex items-center justify-end gap-4">
        <Button
          disabled={isLoading || currentPage === 1}
          className="text-sm flex items-center gap-1 bg-[#D89216] hover:bg-[#D89216]/80"
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
        >
          <ChevronLeft />
          <span>Prev</span>
        </Button>
        <Button
          disabled={isLoading || currentPage === totalPages}
          className="text-sm flex items-center gap-1 bg-[#D89216] hover:bg-[#D89216]/80"
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
        >
          <span>Next</span>
          <ChevronRight />
        </Button>
      </div>
      {/* )} */}
    </div>
  );
};

export default Blogs;
