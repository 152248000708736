import { z } from "zod";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { loginFormSchema } from "src/models/form-schema";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { RootState } from "src/store/store";
import { setPlatform } from "src/store/features/platform";
import { loginAccount } from "src/services/auth";
import { PLATFORM_LIST } from "src/constants";
import { Card, CardContent, CardHeader, CardTitle } from "src/shadcn/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/shadcn/ui/select";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/shadcn/ui/form";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPlatform = useSelector((state: RootState) => state.platform.currentPlatform) || "";

  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: "",
      platform: currentPlatform,
    },
  });

  // states
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleLogin = async (email: string, password: string, platform: string) => {
    setIsLoading(true);
    const res = await loginAccount({ email, password, currentPlatform: platform });
    setIsLoading(false);

    if (res?.access_token) {
      localStorage.setItem("access_token", res.access_token);
      localStorage.setItem("refresh_token", res?.refresh_token);

      navigate("/");
    }
  };

  const onSubmit = (data: z.infer<typeof loginFormSchema>) => {
    const currentPlatform = data.platform;
    if (currentPlatform) {
      dispatch(setPlatform(currentPlatform));
    }

    handleLogin(data.email, data.password, currentPlatform);
  };

  return (
    <Card className="w-full max-w-[450px]">
      <CardHeader>
        <CardTitle className="text-xl">Sign In</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-4 mb-8">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter your email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="Enter your password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="platform"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Platform</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a platform to login" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {PLATFORM_LIST.map((platform) => (
                            <SelectItem
                              key={platform.id}
                              value={platform.value}
                            >
                              {platform.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <Button
                type="submit"
                className="w-full mb-4"
                disabled={!form.formState.isValid || isLoading}
              >
                {isLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
                Sign In
              </Button>
            </form>
          </Form>
        </div>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
