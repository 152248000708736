import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "src/models";

interface AuthState {
  isLogged: boolean;
  user: IUser | null;
  account: {
    email: string;
    password: string;
  } | null;
}

const initialState: AuthState = {
  isLogged: false,
  user: null,
  account: {
    email: "",
    password: "",
  },
};

export const authSlice = createSlice({
  name: "authStore",
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
        user: IUser | null;
      }>,
    ) => {
      const { accessToken, refreshToken, user } = action.payload;

      console.log(accessToken);

      state.user = user;
      state.isLogged = true;
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
    },
    saveAccount: (
      state,
      action: PayloadAction<{ email: string; password: string }>,
    ) => {
      const { email, password } = action.payload;
      state.account = { email, password };
    },
    saveUserInfo: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    removeAccount: (state) => {
      state.account = null;
    },
    logout: (state) => {
      state.isLogged = false;
      state.user = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
  },
});

export const { login, logout, saveAccount, removeAccount, saveUserInfo } =
  authSlice.actions;
export default authSlice.reducer;
