import axios from "axios";

import { handleGlobalError } from "src/lib/utils";
import { IBlog, IBlogResponse } from "src/models";

const neuralpitDomain = process.env.REACT_APP_NEURALPIT_DOMAIN;
const acquiretalentDomain = process.env.REACT_APP_ACQUIRETALENT_DOMAIN;
const cvcheckerDomain = process.env.REACT_APP_CVCHCKER_DOMAIN;
let token: string = "";

function getToken() {
  if (token) {
    return token;
  }

  token = localStorage.getItem("access_token") ?? "";

  return token;
}

// acquiretalent platform
export const createHrBlog = async (title: string, cover_pic: string, html: string) => {
  try {
    const url = `${acquiretalentDomain}/blogs`;
    const res = await axios.post(
      url,
      {
        title,
        cover_pic,
        html,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    if (res.status === 200) {
      return "success";
    }

    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const getHrBlogs = async (lastBlogId?: string): Promise<IBlogResponse | undefined> => {
  try {
    let url = `${acquiretalentDomain}/blogs?limit=8`;
    if (lastBlogId) {
      url += `&last_blog_id=${lastBlogId}`;
    }

    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getHrBlogById = async (id: string): Promise<IBlog | undefined> => {
  const url = `${acquiretalentDomain}/blogs/${id}`;
  try {
    const res = await axios.get(url);
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateHrBlog = async (blog: IBlog) => {
  const { id, ...data } = blog;
  const url = `${acquiretalentDomain}/blogs/${id}`;

  try {
    const res = await axios.patch(url, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteHrBlog = async (id: string) => {
  const url = `${acquiretalentDomain}/blogs/${id}`;
  try {
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

// neuralpit platform
export const createNeuralpitBlog = async (title: string, cover_pic: string, html: string) => {
  try {
    const res = await axios.post(`${neuralpitDomain}/neuralpit/blogs`, {
      title,
      cover_pic,
      html,
      created_date: new Date().toISOString(),
    });

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const getNeuralpitBlogs = async (lastBlogId?: string): Promise<IBlogResponse | undefined> => {
  try {
    let url = `${neuralpitDomain}/neuralpit/blogs?limit=8`;
    if (lastBlogId) {
      url += `&last_blog_id=${lastBlogId}`;
    }

    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getNeuralpitBlogById = async (id: string): Promise<IBlog | undefined> => {
  try {
    const res = await axios.get(`${neuralpitDomain}/neuralpit/blogs/${id}`);
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateNeuralpitBlog = async (blog: IBlog) => {
  const { id, ...data } = blog;
  try {
    const res = await axios.patch(`${neuralpitDomain}/neuralpit/blogs/${id}`, data);
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteNeuralpitBlog = async (id: string) => {
  try {
    const res = await axios.delete(`${neuralpitDomain}/neuralpit/blogs/${id}`);

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

// cvchecker platform
export const createCvcheckerBlog = async (title: string, cover_pic: string, html: string) => {
  try {
    const res = await axios.post(
      `${cvcheckerDomain}/blogs`,
      {
        title,
        cover_pic,
        html,
        created_date: new Date().toISOString(),
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const getCvcheckerBlogs = async (lastBlogId?: string): Promise<IBlogResponse | undefined> => {
  try {
    let url = `${cvcheckerDomain}/blogs?limit=8`;
    if (lastBlogId) {
      url += `&last_blog_id=${lastBlogId}`;
    }

    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getCvcheckerBlogById = async (id: string): Promise<IBlog | undefined> => {
  try {
    const res = await axios.get(`${cvcheckerDomain}/blogs/${id}`);
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateCvcheckerBlog = async (blog: IBlog) => {
  const { id, ...data } = blog;
  try {
    const res = await axios.patch(`${cvcheckerDomain}/blogs/${id}`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteCvcheckerBlog = async (id: string) => {
  try {
    const res = await axios.delete(`${cvcheckerDomain}/blogs/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};
