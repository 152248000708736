import { z } from "zod";

export const loginFormSchema = z.object({
  email: z.string().email({ message: "Invalid email address!" }),
  password: z.string().min(1, { message: "This field is required!" }),
  platform: z.string().min(1, { message: "This field is required!" }),
});

export const blogFormSchema = z.object({
  title: z.string().min(1, { message: "This field is required!" }),
});

export const notificationFormSchema = z.object({
  title: z.string().min(1, { message: "This field is required!" }),
  content: z.string().min(1, { message: "This field is required!" }),
});
